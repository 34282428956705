<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant :disabled="true" type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 작업기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="작업기간"
            name="permitDates"
            v-model="searchParam.permitDates"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 신청부서 -->
          <!-- <c-dept type="search" label="신청부서" name="applicationDeptCd" v-model="searchParam.applicationDeptCd" /> -->
          <c-vendor
            :plantCd="searchParam.plantCd"
            label="신청업체"
            name="applicationDeptCd"
            v-model="searchParam.applicationDeptCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 발급부서 -->
          <!-- <c-dept type="search" label="발급부서" name="issuedDeptCd" v-model="searchParam.issuedDeptCd" /> -->
          <c-vendor
            :plantCd="searchParam.plantCd"
            label="발급업체"
            name="issuedDeptCd"
            v-model="searchParam.issuedDeptCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 발급부서 -->
          <!-- <c-dept type="search" label="발급부서" name="issuedDeptCd" v-model="searchParam.issuedDeptCd" /> -->
          <c-vendor
            label="작업업체"
            :editable="!isVendor"
            name="vendorCd"
            v-model="searchParam.vendorCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 허가서구분 -->
          <c-select
            type="search"
            codeGroupCd="SOP_PERMIT_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="permitTypeCd"
            label="허가서구분"
            v-model="searchParam.permitTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 보충작업 -->
          <c-select
            type="search"
            codeGroupCd="SOP_SUPPLEMENT_WORK_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="supplementWorkTypeCd"
            label="보충작업"
            v-model="searchParam.supplementWorkTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="swpTable"
      title="작업허가서 목록"
      tableId="swp01"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="sopWorkPermitId"
      :selection="popupParam.type"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safe-work-permit',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          vendorCd: '',
          popupMode: 'N',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        permitDates: [],
        applicationDeptCd: null,
        issuedDeptCd: null,
        permitTypeCd: null,
        supplementWorkTypeCd: null,
        swpStepCd: null,
        popupMode: 'N',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '공사현장',
            align: 'center',
            sortable: true,
            style: 'width:160px',
          },
          {
            name: 'workSummary',
            field: 'workSummary',
            label: '작업개요',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:250px',
          },
          {
            name: 'swpStepName',
            field: 'swpStepName',
            label: '진행단계',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '결재진행단계',
            align: 'center',
            sortable: true,
            style: 'width:90px',
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            label: '허가일',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: '허가서구분',
            align: 'center',
            sortable: true,
            style: 'width:70px',
          },
          {
            name: 'supplementWorkTypeNames',
            field: 'supplementWorkTypeNames',
            label: '보충작업',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'applicationDeptUserName',
            field: 'applicationDeptUserName',
            label: '신청업체',
            align: 'center',
            sortable: true,
            style: 'width:110px',
          },
          {
            name: 'issuedDeptUserName',
            field: 'issuedDeptUserName',
            label: '발급업체',
            align: 'center',
            sortable: true,
            style: 'width:110px',
          },
        ],
        data: [],
      },
      editable: true,
      isVendor: false,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.workPermit.list.url
      // code setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd
      }
      if (this.popupParam.popupMode) {
        this.searchParam.popupMode = this.popupParam.popupMode
      }
      if (this.popupParam.vendorCd) {
        if (this.$store.getters.user.innerFlag === 'N') {
          this.searchParam.vendorCd = this.$store.getters.user.deptCd;
          this.isVendor = true;
        }
        // this.searchParam.vendorCd = this.popupParam.vendorCd
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        console.log('ddd')
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '작업허가서';
      this.popupOptions.param = {
        sopWorkPermitId: row ? row.sopWorkPermitId : '',
        permitTypeCd: row ? row.permitTypeCd : '',
      };
      this.popupOptions.target = () => import(`${'./safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['swpTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '작업허가서를 지정하세요.', // 작업허가서를 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
